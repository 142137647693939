import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase, useFirestore } from "react-redux-firebase";
import { useParams, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import moment from "moment";
import HistoryItem from "./historyItem";

const GroupModify = ({auth}) => {

    const navigate = useNavigate();
    const { groupId } = useParams();
    const firestore = useFirestore();
    const [updateCount, setUpdateCount] = useState(0);
    const [inputDic, SetInputDic] = useState({
        plusMemo: "",
        plusPoint: 0,
        minusMemo: "",
        minusPoint: 0
    });

    const [groupInfo, setGroupInfo] = useState({
        name: "",
        admin: false,
        users: [],
        point: 0,
        history: [],
        memo: ""
    });

    const [pointInfo, setPointInfo] = useState({
        date: new Date(),
        madeBy: auth.email,
        memo: "",
        point: 0,
        target: "",
        type: ""
    });

    useFirestoreConnect({
        collection: `licenses/`,
        storeAs: "licenses"
    });
  
    const licenses = useSelector((state) => state.firestore.data.licenses);

    
    useEffect(async () => {
        if (groupId !== "new") {
            var groupDoc = firestore.collection('groups').doc(groupId);

            let infoDoc = await groupDoc.get();
            let collections = await groupDoc.collection("history").get();

            var history = [];
            collections.forEach(collection => {
                history.push(collection.data());
            });
            history = history.reverse();

            setGroupInfo({ ...infoDoc.data(), history: history });
        }

    }, [updateCount, firestore, groupId]);


    const saveButtonHander = (e) => {
        if (groupInfo.name === "") {
            alert("이름을 선택하쇼");
            return;
        }
        if (groupId === "new") {
            firestore
            .collection('groups')
            .doc()
            .set(groupInfo, { merge: true }).then(() => {
                navigate("/admin/systems/groups");
                alert("추가 되었습니다.")
            });
        } else {
            firestore
            .collection('groups')
            .doc(groupId)
            .set(groupInfo, { merge: true }).then(() => {
                navigate("/admin/systems/groups");
                alert("수정 되었습니다.")
            });
        }   
    }

    const pointPlusButtonHander = (e) => {
        console.log('pointaaaaaaaaaaa');
        setPointInfo({...pointInfo, madeBy:auth.email, type:"충전", date: new Date(), point: inputDic.plusPoint, memo:inputDic.plusMemo });
        
        firestore
            .collection('groups')
            .doc(groupId)
            .collection('history')
            .doc(moment().format("YYYY-MM-DD HH:mm:ss"))
            .set({...pointInfo, madeBy:auth.email, type:"충전", date: new Date(), point: inputDic.plusPoint, memo:inputDic.plusMemo });
        firestore
            .collection('groups')
            .doc(groupId)
            .update({point: firestore.FieldValue.increment(inputDic.plusPoint)});
        setUpdateCount(cnt => cnt + 1)

    }
    const pointMinusButtonHander = (e) => {
        console.log('pointmmmmmmmmmmm');

        setPointInfo({ ...pointInfo, madeBy:auth.email, type: "차감", date: new Date(),  point: inputDic.minusPoint, memo:inputDic.minusMemo  });
        firestore
        .collection('groups')
        .doc(groupId)
        .collection('history')
        .doc(moment().format("YYYY-MM-DD HH:mm:ss"))
        .set({ ...pointInfo, madeBy:auth.email, type: "차감", date: new Date(),  point: inputDic.minusPoint, memo:inputDic.minusMemo  });
        firestore
            .collection('groups')
            .doc(groupId)
            .update({point: firestore.FieldValue.increment(-1 * inputDic.minusPoint)});
        setUpdateCount(cnt => cnt + 1)
    }

    return (
        <>
            <div className="container">
                <div className="page__top">
                    <h3 className="page__top--title">그룹관리</h3>
                    <div className="page__top--right">
                        <a onClick={saveButtonHander} className="page__top--add">그룹정보 {groupId === "new" ? "추가" : "수정"}</a>
                    </div>
                </div>

                <div className="user_info">
                        <table className="table">
                            <colgroup>
                                <col style={{ "width": "15%" }} />
                                <col style={{ "width": "auto" }} />
                                <col style={{ "width": "15%" }} />
                                <col style={{ "width": "auto" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>그룹명</th>
                                    <td className="tal"><input type="text" name="" id="" size="40" value={groupInfo.name} onChange={(e) => setGroupInfo({ ...groupInfo, name: e.target.value })} /></td>
                                    <th>관리자 여부</th>
                                    <td className="tal"><input disabled="true" type="text" name="" id="" size="40" value={false} onChange={(e) => console.log} /></td>
                                </tr>
                                <tr>
                                    <th>충전하기</th>
                                    <td className="tal">
                                        <div>
                                        <input type="text" value={inputDic.plusMemo}  onChange={(e) => SetInputDic({ ...inputDic, plusMemo: e.target.value })}  name="" id="" size="40" placeholder="충전 내용" />
                                        <input type="text" value={inputDic.plusPoint}  onChange={(e) => SetInputDic({ ...inputDic, plusPoint: parseInt(e.target.value) })} name="" id="" size="40" placeholder="충전 금액" />

                                        <a onClick={pointPlusButtonHander} className="page__top--add" style={{ float : "right"}}>충전하기</a>
                                        </div>

                                    </td>

                                    <th>차감하기</th>
                                    <td className="tal">
                                        <div>
                                            <input type="text" value={inputDic.minusMemo}  onChange={(e) => SetInputDic({ ...inputDic, minusMemo: e.target.value })} name="" id="" size="40" placeholder="차감 내용" />
                                            <input type="text" value={inputDic.minusPoint}  onChange={(e) => SetInputDic({ ...inputDic, minusPoint: parseInt(e.target.value) })} name="" id="" size="40" placeholder="차감 금액" />

                                            <a onClick={pointMinusButtonHander} className="page__top--add" style={{ float: "right" }}>차감하기</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </div>
        
                <div className="page__top">
                    <h3 className="page__top--title">포인트 내역 (잔여 포인트 : {groupInfo.point ? groupInfo.point.toLocaleString() : "0"})</h3>
                </div>
        <table className="table">
          <colgroup>
            <col style={{ "width": "180px" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "240px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>키</th>
              <th>날짜</th>
              <th>타입</th>
              <th>내용</th>
              <th>매장명</th>
              <th>이름</th>
              <th>전화번호</th> 
              <th>해당 라이센스</th>
              <th>금액</th>
              <th>승인 계정</th>
            </tr>
          </thead>
          <tbody>
            {groupInfo.history &&
              Object.entries(groupInfo.history).map((data, idx) =>
                 <HistoryItem
                    key={idx}
                    idx={idx}
                    refId={data[0]}
                    info={data[1]}
                    license={licenses[data[1].target]}
                  />
              )}
          </tbody>
        </table>
            </div>
        </>
    );
};

export default GroupModify;
