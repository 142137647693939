import React from "react";
import HistoryItem from "./historyItem";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const HistoryList = ({group}) => {

    useFirestoreConnect({
      collection: `licenses/`,
      storeAs: "licenses"
  });

  const licenses = useSelector((state) => state.firestore.data.licenses);

    return (
        <>
            <div className="container">
       
                <div className="page__top">
                    <h3 className="page__top--title">포인트 내역 (잔여 포인트 : {group.point ? group.point.toLocaleString() : "0"})</h3>
                </div>
        <table className="table">
          <colgroup>
            <col style={{ "width": "180px" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "240px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>키</th>
              <th>날짜</th>
              <th>타입</th>
              <th>내용</th>
              <th>매장명</th>
              <th>이름</th>
              <th>전화번호</th> 
              <th>해당 라이센스</th>
              <th>금액</th>
              <th>승인 계정</th>
            </tr>
          </thead>
          <tbody>
            {group.history &&
              Object.entries(group.history).map((data, idx) =>
                 <HistoryItem
                    key={idx}
                    idx={idx}
                    refId={data[0]}
                    info={data[1]}
                    license={licenses[data[1].target]}
                  />
              )}
          </tbody>
        </table>
            </div>
        </>
    );
};

export default HistoryList;
