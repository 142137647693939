import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddTodo from "./addTodo";
import { useFirestoreConnect, useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import AccountItem from "./accountItem";
import { Link } from "react-router-dom";

const AccountList = ({ group }) => {

  const [itemPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [filter, setFilter] = useState("");
  const [combineCount, setCombineCount] = useState(0);
  const [platform, setPlatform] = useState([]);
  useFirestoreConnect(['netflix', 'disney', 'watcha', 'tving', 'wavve', 'coupang'])

  const netflix = useSelector((state) => state.firestore.data.netflix);
  const disney = useSelector((state) => state.firestore.data.disney);
  const watcha = useSelector((state) => state.firestore.data.watcha);
  const tving = useSelector((state) => state.firestore.data.tving);
  const wavve = useSelector((state) => state.firestore.data.wavve);
  const coupang = useSelector((state) => state.firestore.data.coupang);

  const combineItems = (platform, item) => {
    Object.entries(item).map((item, idx) => {
      setTotalItems(items => {
        items[platform + " " + item[0]] = item[1]
        return items
      });
    });
    setCombineCount(combineCount => combineCount + 1);
  }

  useEffect(() => {
    if (filter !== "" || platform.length > 0) {
      let temp = {};
      console.log(group.name)
      Object.entries(totalItems).map((data, idx) => {
        if (platform.includes(data[0].split(" ")[0])) {
          if (
            (group.admin || (data[1].madeBy && data[1].madeBy === group.name))
          ) {
            temp[data[0]] = data[1];
          }
        } else if (
          ((data[1].password && data[1].password.includes(filter))
            || (data[1].payment && data[1].payment.includes(filter))
            || (data[1].memo && data[1].memo.includes(filter))
            || (data[1].startDate && data[1].startDate.includes(filter))
            || (data[1].endDate && data[1].endDate.includes(filter))
            || (data[0] && data[0].includes(filter)))
          && filter !== ""
          && (group.admin || (data[1].madeBy && data[1].madeBy === group.name))

        ) {
          temp[data[0]] = data[1];
        }
      });
      // return temp;
      setFilteredItems(temp);
    } else {
      console.log('else', group.name)
      let temp = {};
      Object.entries(totalItems).map((data, idx) => {
        if (
          (group.admin || (data[1].madeBy && data[1].madeBy === group.name))
        ) {
          console.log(data[0]);
          temp[data[0]] = data[1];
        }

      })
      setFilteredItems(temp);
    }
  }, [filter, platform, group, netflix, disney, watcha, tving, coupang]);

  useEffect(() => {

    let temp = {};
    Object.entries(totalItems).map((data, idx) => {
      if (
        (group.admin || (data[1].madeBy && data[1].madeBy === group.name))
      ) {
        console.log(data[0]);
        temp[data[0]] = data[1];
      }

    })
    setFilteredItems(temp);

  }, [combineCount]);

  useEffect(() => {
    setTotalPage(Math.ceil(Object.keys(filteredItems).length / itemPerPage));

  }, [filteredItems, combineCount]);

  useEffect(() => {
    if (isLoaded(netflix) && !isEmpty(netflix)) {
      combineItems("netflix", netflix);
    }
  }, [netflix]);

  useEffect(() => {
    if (isLoaded(disney) && !isEmpty(disney)) {
      combineItems("disney", disney);
    }
  }, [disney]);


  useEffect(() => {
    if (isLoaded(watcha) && !isEmpty(watcha)) {
      combineItems("watcha", watcha);
    }
  }, [watcha]);

  useEffect(() => {
    if (isLoaded(tving) && !isEmpty(tving)) {
      combineItems("tving", tving);
    }
  }, [tving]);


  useEffect(() => {
    if (isLoaded(wavve) && !isEmpty(wavve)) {
      combineItems("wavve", wavve);
    }
  }, [wavve]);

  useEffect(() => {
    if (isLoaded(coupang) && !isEmpty(coupang)) {
      combineItems("coupang", coupang);
    }
  }, [coupang]);


  const platformHandler = (p) => {
    if (p === "all") {
      setPlatform([]);
    } else {
      if (platform.includes(p)) {
        const idx = platform.findIndex(a => a === p)
        setPlatform(platform.filter((value, index) => index !== idx));
      } else {
        setPlatform([...platform, p]);
      }
    }
  }
  const pageHandler = (e) => {
    setCurrentPage(parseInt(e.target.innerText));
  }

  return (
    <>
      <div className="container">
        <div className="page__top">
          <h3 className="page__top--title">계정관리</h3>
          <div className="page__top--right">
            <form className="page__top--search" onSubmit={e => e.preventDefault()}>
              <input type="text" name="" id="" value={filter} onChange={(e) => setFilter(e.target.value)} />
              <input type="submit" value="검색" />
            </form>
            <Link to="new" className="page__top--add">계정등록</Link>
          </div>
        </div>

        <ul className="tab">
          <li><a onClick={e => platformHandler("all")} className={platform.length == 0 ? "active" : ""}>전체</a></li>
          <li><a onClick={e => platformHandler("netflix")} className={platform.includes("netflix") ? "active" : ""}>넷플릭스</a></li>
          <li><a onClick={e => platformHandler("wavve")} className={platform.includes("wavve") ? "active" : ""}>웨이브</a></li>
          <li><a onClick={e => platformHandler("watcha")} className={platform.includes("watcha") ? "active" : ""}>왓차</a></li>
          <li><a onClick={e => platformHandler("coupang")} className={platform.includes("coupang") ? "active" : ""}>쿠팡플레이</a></li>
          <li><a onClick={e => platformHandler("tving")} className={platform.includes("tving") ? "active" : ""}>티빙</a></li>
          <li><a onClick={e => platformHandler("disney")} className={platform.includes("disney") ? "active" : ""}>디즈니플러스</a></li>
        </ul>

        <table className="table">
          <colgroup>
            <col style={{ "width": "180px" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "240px" }} />
            <col style={{ "width": "160px" }} />
            <col style={{ "width": "160px" }} />
            <col style={{ "width": "160px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>관리업체</th>
              <th>OTT구분</th>
              <th>아이디</th>
              <th>비밀번호</th>
              <th>결제정보</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems &&
              Object.entries(filteredItems).map((data, idx) => {
                if (idx >= itemPerPage * (currentPage - 1) && idx < itemPerPage * currentPage) {
                  return (<AccountItem
                    key={idx}
                    info={data[1]}
                    platform={data[0].split(" ")[0]}
                    account={data[0].split(" ")[1]}
                  />
                  )
                } else {
                  // console.log(idx >= itemPerPage * (currentPage - 1) && idx < itemPerPage * currentPage)
                }
              })}
          </tbody>
        </table>

        <div id="paging">
          {currentPage > 1 && <a onClick={() => setCurrentPage(1)} className="paging__first">처음</a>}
          {currentPage > 1 && <a onClick={() => setCurrentPage(currentPage - 1)} className="paging__prev">이전</a>}
          <ul className="paging__list">
            {
              [...Array(5)].map((x, i) => {
                if (currentPage + i - 2 > totalPage || (totalPage < 5 && i + 1 > totalPage)) {
                  return
                }
                let startPage = 1;
                if (currentPage > 3) {
                  startPage = currentPage - 2;
                }

                return <li key={i}><a onClick={pageHandler} className={currentPage == i + startPage ? "active" : ""}>{i + startPage}</a></li>
              })}
          </ul>
          {currentPage < totalPage && <a onClick={() => setCurrentPage(currentPage + 1)} className="paging__next">다음</a>}
          {currentPage < totalPage && <a onClick={() => setCurrentPage(totalPage)} className="paging__last">마지막</a>}
        </div>

      </div>
    </>
  );
};

export default AccountList;
