import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase, useFirestore, isLoaded, isEmpty } from "react-redux-firebase";
import { useParams, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import moment from "moment";

const UserModify = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const firestore = useFirestore();
    const [updateCount, setUpdateCount] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [currentGroup, setCurrentGroup] = useState("");
    const [userInfo, setUserInfo] = useState({
        name: "",
        admin: false,
        group: "",
        memo: ""
    });
    useFirestoreConnect(['users', 'groups'])
    const users = useSelector((state) => state.firestore.data.users);
    const groups = useSelector((state) => state.firestore.data.groups);

    useEffect(() => {
        if (userId in users) {
            users && Object.entries(users[userId]).map((value, idx) => {
                setUserInfo(obj => {
                    obj[value[0]] = value[1];
                    return obj;
                })
            })
        }
        setUpdateCount(cnt => cnt + 1)
    }, [users]);

    useEffect(() => {
        groups && Object.entries(groups).map((data, idx) => {
            Object.values(data[1].users).map(async user => {
                if (userId === user.id) {
                    setUserInfo({...userInfo, group:data[0]});
                    setCurrentGroup(data[0]);
                }
            })
        })
    }, [groups]);

    useEffect(() => {
        setIsAdmin(userInfo.admin === true ? "1" : "0");
        console.log("useeffect", isAdmin);
    }, [userInfo]);

    const adminHandler = (e) => {
        setIsAdmin(e.target.value);
    }

    const saveButtonHander = (e) => {

        
        if (currentGroup !== userInfo.group) {

            if (currentGroup !== "") {
                firestore
                    .collection('groups')
                    .doc(currentGroup)
                    .update(
                        {
                            users: groups[currentGroup].users.filter(user => user.id !== userId)
                        });
            }

            firestore
            .collection('groups')
            .doc(userInfo.group)
            .update(
                {
                    users: firestore.FieldValue.arrayUnion(firestore.collection('users').doc(userId))
                });
        }
        firestore
            .collection('users')
            .doc(userId)
            .set({ ...userInfo, admin: isAdmin === "1" }, { merge: true }).then(() => {
                navigate("/admin/systems/users");
                alert("수정 되었습니다.")
            });
    }

    return (
        <>
            <div className="container">
                <div className="page__top">
                    <h3 className="page__top--title">그룹관리</h3>
                    <div className="page__top--right">
                        <a onClick={saveButtonHander} className="page__top--add">유저정보 수정</a>
                    </div>
                </div>

                <div className="user_info">
                    <form action="">
                        <table className="table">
                            <colgroup>
                                <col style={{ "width": "15%" }} />
                                <col style={{ "width": "auto" }} />
                                <col style={{ "width": "15%" }} />
                                <col style={{ "width": "auto" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>uid</th>
                                    <td className="tal"><input disabled={true} type="text" name="" id="" size="40" value={userId || ""} /></td>
                                    <th>아바타</th>
                                    <td className="tal"><img src={userInfo.avatarUrl} /></td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td className="tal"><input disabled={true} type="text" name="" id="" size="40" value={userInfo.email || ""} /></td>
                                    <th>표시이름</th>
                                    <td className="tal"><input disabled={true} type="text" name="" id="" size="40" value={userInfo.displayName || ""} /></td>
                                </tr>
                                <tr>
                                    <th>그룹 설정</th>
                                    <td className="tal">
                                        <select value={userInfo.group} onChange={e => setUserInfo({...userInfo, group:e.target.value })}>
                                        <option value="">선택하세요.</option>
                                        {
                                            groups && Object.entries(groups).map((data, idx) => 
                                            <option key={idx} value={data[0]}>{data[1].name}</option>
                                            )   
                                        }
                                        </select>
                                    </td>
                                    <th>활성화</th>
                                    <td className="tal">
                                        <select value={isAdmin} onChange={adminHandler}>
                                            <option value="">선택해주세요</option>
                                            <option value="1">활성화</option>
                                            <option value="0">비활성화</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UserModify;
