import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase, isLoaded } from "react-redux-firebase";
import { Link } from "react-router-dom";
import LicenseItem from "./licenseItem";
import { useFirestore } from "react-redux-firebase";

const LicenseList = ({ group }) => {
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [filteredItems, setFilteredItems] = useState({});
    const [filter, setFilter] = useState("");
    const [ipFilter, setIpFilter] = useState("");
    const [licenseFromIp, setLicenseFromIp] = useState({});
    const firestore = useFirestore();

    useFirestoreConnect({
        collection: `licenses/`,
        storeAs: "licenses"
    });

    const licenses = useSelector((state) => state.firestore.data.licenses);

    // useEffect(() => {
    //     if (isLoaded(licenses)) {
    //         setFilteredItems(licenses);
    //     }
    // }, [itemPerPage, licenses, filter, filteredItems]);

    useEffect(() => {
        setTotalPage(Math.ceil(Object.keys(filteredItems).length / itemPerPage));
    }, [filteredItems]);

    useEffect(() => {

        let temp = {};
        if (filter !== "") {
            Object.entries(licenses).map((licenseData, idx) => {
                if (
                    (
                        (licenseData[1].name && licenseData[1].name.includes(filter))
                        || (licenseData[1].phone && licenseData[1].phone.includes(filter))
                        || (licenseData[1].owner && licenseData[1].owner.includes(filter))
                        || (licenseData[1].kakaoId && licenseData[1].kakaoId.includes(filter))
                        || (licenseData[1].startDate && licenseData[1].startDate.includes(filter))
                        || (licenseData[1].expireDate && licenseData[1].expireDate.includes(filter))
                        || (licenseData[0] && licenseData[0].includes(filter))
                    )
                    && (group.admin || (licenseData[1].madeBy && licenseData[1].madeBy === group.name))
                ) {
                    temp[licenseData[0]] = licenseData[1];
                }
            });
        } else {
            if (licenses) {
                Object.entries(licenses).map((licenseData, idx) => {
                    if (
                        (group.admin || (licenseData[1].madeBy && licenseData[1].madeBy === group.name))
                    ) {
                        temp[licenseData[0]] = licenseData[1];
                    }
                });
            }
        }

        setFilteredItems(temp);
            // .map((data, idx) => {
            //     console.log('bbbbbbb', idx, data);
            // })
    }, [filter, licenses, group]);


    const searchIpFilter = async () => {
        var computerDoc = await firestore.collection('computers').doc(ipFilter).get();
        console.log(computerDoc);
        if (computerDoc.exists) {
            console.log(computerDoc.data().license)
            let getLicense = await computerDoc.data().license.get();
            setLicenseFromIp(getLicense)
        }

    }

    const pageHandler = (e) => {
        setCurrentPage(parseInt(e.target.innerText));
    }
    return (
        <>

            {/* <div className="left_menu">
                    <ul className="left_menu__list">
                        <li className="active"><a href="#">메뉴1</a></li>
                        <li><a href="#">메뉴2</a></li>
                        <li><a href="#">메뉴3</a></li>
                    </ul>
                </div> */}
            <div className="container">
                <div className="page__top">
                    <h3 className="page__top--title">아이피검색</h3>
                    <div className="page__top--right">
                        <form className="page__top--search" onSubmit={e => e.preventDefault()}>
                            <input type="text" name="" id="" value={ipFilter} onChange={(e) => setIpFilter(e.target.value)} placeholder="검색할 아이피를 입력해주세요." />
                            
                        </form>
                        <button onClick={searchIpFilter} className="page__top--add" >검색</button>
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>라이센스</th>
                            <th>관리업체</th>
                            <th>이름(매장명)</th>
                            <th>성함</th>
                            <th>연락처</th>
                            <th>카카오톡ID</th>
                            <th>시작일</th>
                            <th>만료일</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        licenseFromIp.data &&
                        <LicenseItem
                            key="0"
                            info={licenseFromIp.data()}
                            license={licenseFromIp.id}
                        />
                    }
                        
                    </tbody>
                </table>
                <div className="page__top">
                    <h3 className="page__top--title">라이센스관리</h3>
                    <div className="page__top--right">
                        <form className="page__top--search" onSubmit={e => e.preventDefault()}>
                            <input type="text" name="" id="" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="검색어를 입력해주세요." />
                            {/* <input type="submit" value="검색" /> */}
                        </form>
                        <Link to="new" className="page__top--add">라이센스등록</Link>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>라이센스</th>
                            <th>관리업체</th>
                            <th>이름(매장명)</th>
                            <th>성함</th>
                            <th>연락처</th>
                            <th>카카오톡ID</th>
                            <th>시작일</th>
                            <th>만료일</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredItems &&
                            Object.entries(filteredItems)
                                .sort((a, b) => {
                                    return new Date(a[1].expireDate) - new Date(b[1].expireDate)
                                })
                                .map((data, idx) => {
                                    if (idx >= itemPerPage * (currentPage - 1) && idx < itemPerPage * currentPage) {
                                        return (
                                        <LicenseItem
                                            key={idx}
                                            info={data[1]}
                                            license={data[0]}
                                        />
                                        )
                                    } else {
                                        // console.log(idx >= itemPerPage * (currentPage - 1) && idx < itemPerPage * currentPage)
                                    }
                                })
                            // .map((data, idx) => {
                            //     console.log(idx, data);
                            //     if (idx >= itemPerPage * (currentPage - 1) && idx < itemPerPage * currentPage) {
                            //         return (<LicenseItem
                            //             key={idx}
                            //             info={data[1]}
                            //             license={data[0]}
                            //         />
                            //         )
                            //     } else {

                            //         // console.log(idx >= itemPerPage * (currentPage - 1) && idx < itemPerPage * currentPage)
                            //     }
                            // })
                        }
                    </tbody>
                </table>

                <div id="paging">
                    {currentPage > 1 && <a onClick={() => setCurrentPage(1)} className="paging__first">처음</a>}
                    {currentPage > 1 && <a onClick={() => setCurrentPage(currentPage - 1)} className="paging__prev">이전</a>}
                    <ul className="paging__list">
                        {
                            [...Array(5)].map((x, i) => {
                                if (currentPage + i - 2 > totalPage || (totalPage < 5 && i + 1 > totalPage)) {
                                    return
                                }
                                let startPage = 1;
                                if (currentPage > 3) {
                                    startPage = currentPage - 2;
                                }

                                return <li key={i}><a onClick={pageHandler} className={currentPage == i + startPage ? "active" : ""}>{i + startPage}</a></li>
                            })}
                    </ul>
                    {currentPage < totalPage && <a onClick={() => setCurrentPage(currentPage + 1)} className="paging__next">다음</a>}
                    {currentPage < totalPage && <a onClick={() => setCurrentPage(totalPage)} className="paging__last">마지막</a>}
                </div>
            </div>
        </>
    );
};

export default LicenseList;
