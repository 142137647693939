import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase, isLoaded } from "react-redux-firebase";
import { Link } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import OnlineItem from "./dashboard/onlineItem";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);


export const data_line = { //라인차트 데이터
  labels: ['04.16', '04.17', '04.18', '04.19', '04.20', '04.21', '04.22', '04.23', '04.24', '04.25'],
  datasets: [
    {
      label: '넷플릭스',
      data: [16, 20, 24, 22, 26, 32, 36, 40, 38, 38],
      backgroundColor: '#e50914',
      borderColor: '#e50914'
    },
    {
      label: '왓차',
      data: [10, 14, 14, 12, 14, 18, 23, 25, 23, 20],
      backgroundColor: '#ff0558',
      borderColor: '#ff0558'
    },
    {
      label: '쿠팡플레이',
      data: [8, 8, 6, 4, 4, 12, 10, 14, 14, 16],
      backgroundColor: '#0bc0ff',
      borderColor: '#0bc0ff'
    },
    {
      label: '티빙',
      data: [14, 18, 14, 13, 16, 14, 11, 13, 18, 14],
      backgroundColor: '#a500ef',
      borderColor: '#a500ef'
    },
    {
      label: '디즈니플러스',
      data: [14, 16, 10, 14, 12, 10, 16, 14, 14, 18],
      backgroundColor: '#124396',
      borderColor: '#124396'
    },
    {
      label: '유튜브프리미엄',
      data: [20, 20, 18, 16, 15, 17, 16, 14, 15, 16],
      backgroundColor: '#666',
      borderColor: '#666'
    }
  ],
};

export const options_line = { //라인차트 옵션
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: true,
        borderColor: '#eee',
        borderWidth: 0,
        lineWidth: 1,
        borderDash: [2, 2],
      }
    },
    y: {
      display: false
    }
  },
  elements: {
    line: {
      borderWidth: 1,
      z: 1
    },
    point: {
      pointBackgroundColor: '#fff',
      radius: 4,
      z: 1
    }
  },
  plugins: {
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 30,
        boxHeight: 6,
        color: '#000',
        font: {
          size: 12
        }
      }
    }
  }
};


export const data_bar = { //바차트 데이터
  labels: ['넷플릭스', '왓차', '쿠팡플레이', '티빙', '디즈니플러스', '유튜브프리미엄'],
  datasets: [
    {
      label: '사용중',
      data: [14, 7, 2, 7, 8, 10],
      backgroundColor: '#08e8c7',
      borderRadius: 6,
      barPercentage: 0.6
    },
    {
      label: '전체계정',
      data: [30, 15, 8, 15, 15, 30],
      backgroundColor: '#dcdee8',
      borderRadius: 6,
      barPercentage: 0.6
    },
  ]
};

export const options_bar = { //바차트 옵션
  responsive: true,
  title: {
    display: false
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        borderColor: '#666',
        borderWidth: 1,
        lineWidth: 0,
        z: 1
      },
      ticks: {
        color: '#000000',
        font: {
          size: 11
        }
      }
    },
    y: {
      stacked: true,
      display: false,
    }
  },
  plugins: {
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 30,
        boxHeight: 6,
        color: '#000',
        font: {
          size: 12
        }
      }
    }
  }
};

//tooltip custom
export const pieChartText = {
  id: 'pieChartText',
  afterDraw(chart, args, options) {
    const { ctx } = chart;
    ctx.save();

    function labelPercent(label, x, y) {
      ctx.font = '16px "Noto Sans KR"';
      ctx.fillStyle = 'white';
      ctx.fillText(label, x, y)
    }

    function labelText(label, x, y) {
      ctx.font = '10px "Noto Sans KR"';
      ctx.fillStyle = 'white';
      ctx.fillText(label, x, y)
    }

    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { x, y } = datapoint.tooltipPosition();
        //console.log(datapoint.tooltipPosition())

        //text		
        const percent = chart.data.datasets[i].data[index] + '%';
        const percentWidth = ctx.measureText(percent).width;

        const text = chart.data.labels[index];
        const textWidth = ctx.measureText(text).width;

        //text
        labelPercent(percent, x - (percentWidth / 2) - 5, y - 8);
        labelText(text, x - (textWidth / 2), y + 5);

      })
    });
  }
}

export const options_doughnut = { //도넛차트 옵션
  responsive: true,
  layout: {
    padding: {
      left: 20,
      right: 20
    }
  },
  title: {
    display: false
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  }
};


const Dashboard = ({ group }) => {

  const [onlines, setOnlines] = useState([]);
  const [data_doughnut, setData_doughnut] = useState({ //도넛차트 데이터
    labels: ['넷플릭스', '왓차', '쿠팡플레이', '티빙', '디즈니플러스', '유튜브프리미엄'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [30, 5, 4, 10, 14, 20],
        backgroundColor: ['#e50914', '#ff0558', '#0bc0ff', '#a500ef', '#124396', '#666'],
        borderWidth: 0
      }
    ]
  });

  const calculateWeight = (datas) => {
    var dict = {};
    Object.entries(datas).map((data, idx) => {
      if (dict[data[1].platform]) {
        dict[data[1].platform] += 1;
      } else {
        dict[data[1].platform] = 1;
      }
    })

    console.log(Object.values(dict));
    
    const total = Object.values(dict).reduce(function(acc, val) { return acc + val; }, 0)

    return [
      (dict['netflix']/total * 100).toFixed(2), 
      (dict['watcha']/total* 100).toFixed(2), 
      (dict['coupang']/total* 100).toFixed(2),
      (dict['tving']/total* 100).toFixed(2),
      (dict['disney']/total* 100).toFixed(2), 
      (dict['youtube']/total* 100).toFixed(2)];
  }

  useEffect(() => {
    if (onlines.length == 0) {
      fetch("https://ott-admin.jabi.us/api/onlines?t=json").then(async (response) => {
        // fetch("http://localhost:5001/jabiott/us-central1/onlines?t=json").then(async (response) => {
        
        const data = await response.json();
        setOnlines(data);

        const weight = calculateWeight(data);
        setData_doughnut({...data_doughnut, datasets:[{...data_doughnut.datasets[0], data:weight}]})
      });
    }
  });

  return (
    <>


      <div className="dashboard">
        <div className="container">
          <div className="dash-row">
            <div className="dash-col chart1">
              <div className="dash-box">
                <div className="dash-top">
                  <h5 className="dash-title">기간별 OTT 서비스 사용 추이</h5>
                </div>
                <Line options={options_line} data={data_line} />
              </div>
            </div>
            <div className="dash-col chart1">
              <div className="dash-box">
                <div className="dash-top">
                  <h5 className="dash-title">실시간 계정 사용 현황</h5>
                </div>
                <Bar options={options_bar} data={data_bar} />
              </div>
            </div>
            <div className="dash-col chart2">
              <div className="dash-box">
                <div className="dash-top">
                  <h5 className="dash-title">실시간 OTT 플레이 비중</h5>
                </div>
                <Doughnut options={options_doughnut} data={data_doughnut} plugins={[pieChartText]} />
              </div>
            </div>
          </div>

          <div className="dash-row">
            <div className="dash-col w100">
              <div className="dash-box">
                <div className="dash-top">
                  <h5 className="dash-title">실시간 OTT 사용현황</h5>
                </div>
                <table className="dash-table">
                  <colgroup>
                    <col style={{ width: "60px" }} />
                    <col style={{ width: "200px" }} />
                    <col style={{ width: "100px" }} />
                    <col style={{ width: "240px" }} />
                    <col style={{ width: "auto" }} />
                    <col style={{ width: "200px" }} />
                    <col style={{ width: "200px" }} />
                    <col style={{ width: "200px" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>ip</th>
                      <th>platform</th>
                      <th>account</th>
                      <th>url</th>
                      <th>created</th>
                      <th>updated</th>
                      <th>latest</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.entries(onlines).map((data, idx) => {
                        return <OnlineItem key={idx} idx={idx} item={data[1]} ></OnlineItem>
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Dashboard;
