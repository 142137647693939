import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { Link } from "react-router-dom";
import GroupModify from "./groupModify";
import GroupList from "./groupList";

const GroupComponent = () => {

  return (
    <>
    <GroupModify />
    <GroupList />
    </>
  );
};

export default GroupComponent;
