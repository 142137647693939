import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useParams, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import moment from "moment";
import HistoryItem from "./systems/historyItem";

const LicenseModify = ({ group, auth }) => {
    const navigate = useNavigate();
    const { licenseId } = useParams();
    const firestore = useFirestore();
    const [licenseInfo, setLicenseInfo] = useState({
        name: "",
        owner: "",
        startDate: moment().toDate(),
        expireDate: moment().add(5, 'days').toDate(),
        kakaoId: "",
        phone: "",
        memo: "",
        madeBy: group.name,
        history: []
    });
    const [futureMonth, setFutureMonth] = useState("");
    const [payamount, setPayamount] = useState(0);
    const [paymemo, setPaymemo] = useState("연장 차감");
    const [ipInfo, setIpInfo] = useState({
        a: "0",
        b: "0",
        c: "0",
        d: "0",
        endD: "0"
    });

    const [checkedIp, setCheckedIp] = useState([]);

    useFirestoreConnect([{
        collection: "computers",
        where: ['license', '==', firestore.collection("licenses").doc(licenseId)]
    }])

    useFirestoreConnect({
        collection: `licenses/`,
        storeAs: "licenses"
    });

    const licenses = useSelector((state) => state.firestore.data.licenses);

    const computers = useSelector((state) => state.firestore.data.computers);

    useEffect(async () => {
        if (licenseId !== "new") {
            let licenseDoc = firestore.collection("licenses").doc(licenseId);

            let licenseInfoGet = await licenseDoc.get();
            let collections = await licenseDoc.collection("history").get();

            var history = [];
            collections.forEach(collection => {
                history.push(collection.data());
            });
            history = history.reverse();
            setLicenseInfo({ ...licenseInfoGet.data(), history: history });


            var currentDate = moment(licenseInfoGet.data().expireDate);
            var fm = moment(currentDate).add(1, 'M');
            var futureMonthEnd = moment(fm).endOf('month');

            if (currentDate.date() != fm.date() && fm.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
                fm = fm.add(1, 'd');
            }
            setFutureMonth(fm.format("YYYY-MM-DD"));

        }

    }, [firestore]);

    const checkAllHandler = (e) => {
        setCheckedIp(Object.keys(computers));
    }

    const uncheckAllHandler = (e) => {
        setCheckedIp([]);
    }

    const removeComputersHandler = async (e) => {

        if (window.confirm(`${checkedIp.length}개의 컴퓨터를 삭제하시겠습니까?`)) {
            const batch = firestore.batch();

            for (var idx = 0; idx <= checkedIp.length; idx++) {
                const ip = checkedIp[idx];
                firestore.collection("computers")
                    .doc(ip)
                    .delete()
            }

            batch.commit().then((result) => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);

            });

        } else {
            alert("취소 되었습니다.");
        }

    }

    const saveButtonHander = (e) => {
        if (licenseId !== "new") {
            firestore
                .collection("licenses")
                .doc(licenseId)
                .set({
                    ...licenseInfo,
                    startDate: moment(licenseInfo.startDate).format("YYYY-MM-DD"),
                    expireDate: moment(licenseInfo.expireDate).format("YYYY-MM-DD")
                }, { merge: true }).then(() => {
                    navigate("/admin/licenses");
                    alert("수정 되었습니다.")
                });
        } else {
            firestore
                .collection("licenses")
                .doc()
                .set({
                    ...licenseInfo,
                    startDate: moment(licenseInfo.startDate).format("YYYY-MM-DD"),
                    expireDate: moment(licenseInfo.expireDate).format("YYYY-MM-DD")
                }, { merge: true }).then(() => {
                    navigate("/admin/licenses");
                    alert("추가 되었습니다.")
                });
        }
    }

    const add1Month = async () => {
        const groupInfo = await firestore.collection("groups").doc(group.uid).get();

        if (groupInfo.data().point < payamount) {
            alert(`현재 잔여포인트는 ${groupInfo.data().point} 입니다. 충전후 이용해주세요.`);
            return;
        }

        var pointInfo = {
            date: new Date(),
            madeBy: auth.email,
            memo: `${licenseInfo.expireDate} 에서 ${futureMonth}로 변경 \n ${paymemo}`,
            point: payamount,
            target: licenseId,
            type: "연장"
        }

        firestore
            .collection('groups')
            .doc(group.uid)
            .collection('history')
            .doc(moment().format("YYYY-MM-DD HH:mm:ss"))
            .set(pointInfo);
        firestore
            .collection('groups')
            .doc(group.uid)
            .update({ point: firestore.FieldValue.increment(-1 * payamount) })
            .then(() => {

                firestore
                    .collection('licenses')
                    .doc(licenseId)
                    .collection('history')
                    .doc(moment().format("YYYY-MM-DD HH:mm:ss"))
                    .set(pointInfo);

                firestore
                    .collection("licenses")
                    .doc(licenseId)
                    .set({
                        expireDate: futureMonth
                    }, { merge: true }).then(() => {
                        window.location.reload();
                        alert("연장 되었습니다.")
                    });
            });
    }

    const removeButtonHander = (e) => {
        if (licenseId !== "new") {
            const confirm = window.confirm("정말 삭제하시겠습니까?");

            if (confirm) {
                firestore
                    .collection("licenses")
                    .doc(licenseId)
                    .delete()
                    .then(() => {
                        navigate("/admin/licenses");
                        alert("삭제 되었습니다.")
                    })
            }
        }
    }

    const checkboxHandler = (e, ip) => {

        if (e.target.checked) {
            setCheckedIp([...checkedIp, ip]);
        } else {
            setCheckedIp(checkedIp.filter((ip2) => ip != ip2));
        }
    }

    const addComputers = async () => {
        if (licenseId === "new") {
            alert("라이센스 추가 후 등록해주세요.")
            return;
        }

        const batch = firestore.batch();

        for (var d = ipInfo.d; d <= ipInfo.endD; d++) {
            firestore.collection("computers")
                .doc(`${ipInfo.a}.${ipInfo.b}.${ipInfo.c}.${d}`)
                .set({
                    license: firestore.collection('licenses').doc(licenseId),
                    created: moment().toDate()
                });
        }

        await batch.commit();

    }

    return (
        <>
            <div className="container">
                <div className="page__top">
                    <h3 className="page__top--title">라이센스관리</h3>
                    <div className="page__top--right">
                        <a onClick={saveButtonHander} className="page__top--add">회원정보 {licenseId === "new" ? "추가" : "수정"}</a>
                        {
                            licenseId !== "new" &&
                            <a onClick={removeButtonHander} className="page__top--remove">회원삭제</a>
                        }
                    </div>
                </div>

                <div className="user_info">
                    <table className="table">
                        <colgroup>
                            <col style={{ "width": "15%" }} />
                            <col style={{ "width": "auto" }} />
                            <col style={{ "width": "15%" }} />
                            <col style={{ "width": "auto" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>이름(매장명)</th>
                                <td className="tal"><input type="text" name="" id="" size="40" value={licenseInfo.name && licenseInfo.name} onChange={(e) => setLicenseInfo({ ...licenseInfo, name: e.target.value })} /></td>
                                <th>계약대수</th>
                                <td className="tal">{computers && Object.keys(computers).length}</td>
                            </tr>
                            <tr>
                                <th>성함</th>
                                <td className="tal"><input type="text" name="" id="" size="40" value={licenseInfo.owner && licenseInfo.owner} onChange={(e) => setLicenseInfo({ ...licenseInfo, owner: e.target.value })} /></td>
                                <th>연락처</th>
                                <td className="tal"><input type="text" name="" id="" size="40" value={licenseInfo.phone && licenseInfo.phone} onChange={(e) => setLicenseInfo({ ...licenseInfo, phone: e.target.value })} /></td>

                            </tr>
                            <tr>
                                <th>시작일</th>
                                <td className="tal">
                                    <DatePicker
                                        disabled={true}
                                        dateFormat="yyyy-MM-dd"
                                        locale={ko}
                                        selected={moment(licenseInfo.startDate, 'YYYY-MM-DD').toDate()}
                                        onChange={date => {
                                            setLicenseInfo({ ...licenseInfo, startDate: moment(date).format("YYYY-MM-DD") })
                                        }}
                                    />
                                </td>
                                <th>종료일</th>
                                <td className="tal">
                                    <input disable={true} type="text" name="" id="" size="40" value={moment(licenseInfo.expireDate).format("YYYY-MM-DD")} />
                                    {/* <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        locale={ko}
                                        selected={moment(licenseInfo.expireDate, 'YYYY-MM-DD').toDate()}
                                        onChange={date => {
                                            setLicenseInfo({ ...licenseInfo, expireDate: moment(date).format("YYYY-MM-DD") })
                                        }}
                                    /> */}
                                </td>
                            </tr>
                            <tr>
                                <th>카카오톡ID</th>
                                <td className="tal"><input type="text" name="" id="" size="40" value={licenseInfo.kakaoId && licenseInfo.kakaoId} onChange={(e) => setLicenseInfo({ ...licenseInfo, kakaoId: e.target.value })} /></td>
                                <th>연장하기</th>
                                <td className="tal">
                                    날짜 <span style={{ padding: "10px" }}></span><input type="text" name="" id="" size="40" value={futureMonth} onChange={e => setFutureMonth(e.target.value)} /><br></br>
                                    메모 <span style={{ padding: "10px" }}></span><input type="text" name="" id="" size="40" value={paymemo} onChange={e => setPaymemo(e.target.value)} /><br></br>
                                    금액 <span style={{ padding: "10px" }}></span><input type="text" name="" id="" size="40" value={payamount} onChange={e => setPayamount(e.target.value)} />
                                    <button className="ip_add__btn" onClick={add1Month}>한달 연장하기</button>
                                    {/* <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        locale={ko}
                                        selected={moment(licenseInfo.expireDate, 'YYYY-MM-DD').toDate()}
                                        onChange={date => {
                                            setLicenseInfo({ ...licenseInfo, expireDate: moment(date).format("YYYY-MM-DD") })
                                        }}
                                    /> */}
                                </td>
                            </tr>

                            <tr>
                                <th>메모</th>
                                <td colSpan={3} className="tal"><textarea style={{ width: "100%", height: "300px", resize: "none", "text-indent": "0px" }} value={licenseInfo.memo && licenseInfo.memo} onChange={e => setLicenseInfo({ ...licenseInfo, memo: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>연장 내역</th>
                                <td colSpan={3}>
                                    <table className="table">
                                        <colgroup>
                                            <col style={{ "width": "180px" }} />
                                            <col style={{ "width": "auto" }} />
                                            <col style={{ "width": "auto" }} />
                                            <col style={{ "width": "240px" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>키</th>
                                                <th>날짜</th>
                                                <th>타입</th>
                                                <th>내용</th>
                                                <th>매장명</th>
                                                <th>이름</th>
                                                <th>전화번호</th>
                                                <th>해당 라이센스</th>
                                                <th>금액</th>
                                                <th>승인 계정</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {licenseInfo.history &&
                                                Object.entries(licenseInfo.history).map((data, idx) =>
                                                    <HistoryItem
                                                        key={idx}
                                                        idx={idx}
                                                        refId={data[0]}
                                                        info={data[1]}
                                                        license={licenses[data[1].target]}
                                                    />
                                                )}
                                            {
                                                licenseInfo.history.length === 0 &&
                                                <tr>
                                                    <td colSpan={7}>연장 내역이 없습니다.</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* 
                <div className="page__top">
                    <h3 className="page__top--title">PC관리</h3>
                    <div className="page__top--right">
                        <a href="#" className="page__top--add">추가</a>
                    </div>
                </div> */}
                <div className="page__top mt60">
                    <h3 className="page__top--title">IP관리</h3>
                </div>

                <div className="ip_add">
                    <p className="ip_add__title">IP 등록</p>
                    <ul className="ip_add__input">
                        <li><input type="text" name="" id="" value={ipInfo.a} onChange={e => setIpInfo({ ...ipInfo, a: e.target.value })} /></li>
                        <li><input type="text" name="" id="" value={ipInfo.b} onChange={e => setIpInfo({ ...ipInfo, b: e.target.value })} /></li>
                        <li><input type="text" name="" id="" value={ipInfo.c} onChange={e => setIpInfo({ ...ipInfo, c: e.target.value })} /></li>
                        <li><input type="text" name="" id="" value={ipInfo.d} onChange={e => setIpInfo({ ...ipInfo, d: e.target.value })} /></li>
                        <li>~</li>
                        <li><input type="text" name="" id="" value={ipInfo.endD} onChange={e => setIpInfo({ ...ipInfo, endD: e.target.value })} /></li>
                    </ul>
                    <a onClick={addComputers} className="ip_add__btn">IP 대역 채우기</a>
                </div>
                <ul className="ip_add__desc">
                    <li>IP 대역을 입력하시고 'IP 대역 채우기' 버튼을 클릭하시면, 등록하실 IP 리스트가 아래 칸에 자동으로 표기 됩니다.</li>
                    <li>2개 대역 이상 등록 시, 한 개 대역을 먼저 등록하신 후, 순차적으로 다른 대역을 입력하시기 바랍니다.</li>
                </ul>

                <div className="page__top">
                    <ul className="ip_modify__btn">
                        <li><a onClick={removeComputersHandler} className="bk">선택 삭제</a></li>
                        <li><a onClick={uncheckAllHandler}>선택 해제</a></li>
                        <li><a onClick={checkAllHandler} className="grn">전체 선택</a></li>
                    </ul>
                    <div className="page__top--right">
                        <form className="page__top--search">
                            <input type="text" name="" id="" placeholder="검색어를 입력해주세요." />
                        </form>
                    </div>
                </div>

                <table className="table">
                    <colgroup>
                        <col style={{ "width": "100px" }} />
                        <col style={{ "width": "auto" }} />
                        <col style={{ "width": "180px" }} />
                        <col style={{ "width": "180px" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th><input type="checkbox" name="" id="" /></th>
                            <th>IP주소</th>
                            <th>사용여부</th>
                            <th>변경일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        {computers &&
                            Object.entries(computers).map((item, idx) =>
                                <tr key={idx}>
                                    <td><input type="checkbox" name="" id="" checked={checkedIp.includes(item[0])} onChange={e => checkboxHandler(e, item[0])} /></td>
                                    <td>{item[0]}</td>
                                    <td>사용</td>
                                    <td>{item[1] && item[1].created && moment(item[1].created.toDate()).format('yy/MM/DD HH:mm')}</td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default LicenseModify;