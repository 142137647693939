import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const GNBHeader = ({groupInfo}) => {
    const { pathname } = useLocation();
    const firebase = useFirebase();
    const { uid } = useSelector((state) => state.firebase.auth);
    const [isAdminGroup, setIsAdminGroup] = useState(false);

    useFirestoreConnect(['groups']);   
    const groups = useSelector((state) => state.firestore.data.groups);

    useEffect(() => {
        groups && Object.values(groups).map((item) => {
          Object.values(item.users).map(async user => {
            if (uid === user.id) {
              setIsAdminGroup(item.admin);
            }
          });
        });
    }, [groups]);

    function logout() {
        firebase
            .logout();
    }

    return (
        <nav id="header">
            <div className="container">
                <h1 className="header__title"><Link to="dashboard">ottv</Link></h1>
                <ul className="header__menu">
                    <li><Link to="licenses" className={pathname.startsWith("/admin/licenses") ? "active" : ""}>라이센스관리</Link></li>
                    <li><Link to="accounts" className={pathname.startsWith("/admin/accounts") ? "active" : ""}>계정관리</Link></li>
                    
                    {isAdminGroup === true &&
                        <>
                            <li><Link to="systems" className={pathname.startsWith("/admin/systems") ? "active" : ""}>시스템관리</Link></li>
                            <li><Link to="systems/groups" className={pathname.startsWith("/admin/systems/groups") ? "active" : ""}>그룹관리</Link></li>
                            <li><Link to="systems/users" className={pathname.startsWith("/admin/systems/users") ? "active" : ""}>회원관리</Link></li>
                        </>
                    }
                    <li><Link to="pointHistory">잔여포인트 :{groupInfo.point ? groupInfo.point.toLocaleString() : "0"}</Link></li>
                    
                </ul>
                <a onClick={logout} className="header__logout">로그아웃</a>
            </div>
        </nav>
    )
}


export default GNBHeader;
