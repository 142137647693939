import { useNavigate } from "react-router";

const AccountItem = ({ idx, info, platform, account }) => {
  let navigate = useNavigate();

  
  return (
    <tr onClick={() => navigate(`/admin/accounts/${platform}/${account}`)}>
      <td>{info.madeBy}</td>
      <td>{platform}</td>
      <td>{account}</td>
      <td>****</td>
      <td>{info.payment}</td>
      <td>{info.startDate}</td>
      <td>{info.endDate}</td>
      <td><span style={{ "color": "#ff0000" }} >{info.disable?"비활성화":""}</span></td>
    </tr>
  )
}

export default AccountItem