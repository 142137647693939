import { useNavigate } from "react-router";

const GroupItem = ({ idx, info, refId }) => {
  let navigate = useNavigate();

  console.log(idx)
  console.log(info)
//   <th>키</th>
//   <th>이름</th>
//   <th>관리그룹 여부</th>
//   <th>유저수</th>
//   <th>관리그룹 여부</th>


  return (
    <tr onClick={() => navigate(`/admin/systems/groups/${refId}`)} >
        <td>{refId}</td>
      <td>{info.name}</td>
      <td>{info.admin?"관리자 그룹": "일반 그룹"}</td>
      <td>{info.users.length}</td>
      <td>{info.point || "0"}</td>
    </tr>
  )
}

export default GroupItem