import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfcgJB3lFN-03lZV8l_r50o-aNHwnYLLs",
  authDomain: "jabiott.firebaseapp.com",
  databaseURL: "https://jabiott-default-rtdb.firebaseio.com",
  projectId: "jabiott",
  storageBucket: "jabiott.appspot.com",
  messagingSenderId: "258979763849",
  appId: "1:258979763849:web:bc0c4ef17fdbfeccc99858",
  measurementId: "G-NC8SH31HP7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();

// export const googleProvider = new firebase.auth.GoogleAuthProvider();
export { firebase };
