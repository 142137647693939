import moment from "moment";

const OnlineItem = ({ idx, item }) => {
    const data = item;
  return (
    <tr>
    <td className="tac">{idx + 1}</td>
    <td className="tac">{data.ip}</td>
    <td className="tac">{data.platform}</td>
    <td className="tac">{data.account}</td>
    <td><a target="_blank" href={data.url} className="dash-table__link">{data.url}</a></td>
    <td className="tac">{data.created && data.created.replace("T", " ").replace(".000Z", "")}</td>
    <td className="tac">{data.updated && data.updated.replace("T", " ").replace(".000Z", "")}</td>
    <td className="tac">{data.latest && data.latest.replace("T", " ").replace(".000Z", "")}</td>
  </tr>
  )
}

export default OnlineItem