import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import GroupItem from "./groupItem";
import { Link } from "react-router-dom";

const GroupList = () => {
  
  useFirestoreConnect(['groups'])

  const groups = useSelector((state) => state.firestore.data.groups);

  return (
    <>
      <div className="container">
        <div className="page__top">
          <h3 className="page__top--title">그룹관리</h3>
          <div className="page__top--right">
            <Link to="new" className="page__top--add">그룹등록</Link>
          </div>
        </div>

        <table className="table">
          <colgroup>
            <col style={{ "width": "180px" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "240px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>키</th>
              <th>이름</th>
              <th>관리그룹 여부</th>
              <th>유저수</th>
              <th>잔여 포인트</th>
            </tr>
          </thead>
          <tbody>
            {groups &&
              Object.entries(groups).map((data, idx) =>
                 <GroupItem
                    key={idx}
                    idx={idx}
                    refId={data[0]}
                    info={data[1]}
                  />
              )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GroupList;
