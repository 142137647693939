import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddTodo from "./addTodo";
import { useFirestoreConnect, useFirebase, useFirestore } from "react-redux-firebase";
import { useParams, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import moment from "moment";

const AccountModify = ({group}) => {
    const navigate = useNavigate();
    const { platform, accountId } = useParams();
    const firestore = useFirestore();
    const [selectPlatform, setSelectPlatform] = useState(platform === "new" ? "" : platform);
    const [currentAccont, setCurrentAccount] = useState(platform === "new" ? "" : accountId);
    const [updateCount, setUpdateCount] = useState(0);
    const [accountInfo, setAccountInfo] = useState({
        name: "",
        startDate: moment().toDate(),
        endDate: moment().add(30, 'days').toDate(),
        currentStreaming: 0,
        password: "",
        payment: "",
        memo: "",
        madeBy: group.name,
        disable:false
    });

    useEffect(async () => {
        console.log("aaa")
        if (platform !== "new") {
            let accountInfoDoc = await firestore.collection(platform).doc(accountId).get();
            Object.entries(accountInfoDoc.data()).map((value, idx) => {
                console.log({ [value[0]]: value[1] });
                setAccountInfo(obj => {
                    obj[value[0]] = value[1];
                    return obj;
                })
            })
        }
        setUpdateCount(cnt=> cnt+1)
    }, [firestore]);


    const setDisable = (e) => {
        e.preventDefault();
        console.log("disable");
        if (platform !== "new") {
            firestore
            .collection(selectPlatform)
            .doc(currentAccont)
            .set({
                disable:accountInfo.disable?false:true
            }, { merge: true }).then(() => {
                alert(accountInfo.disable?"활성화 되었습니다.":"비활성화 되었습니다.")
                navigate("/admin/accounts");
            });
        }
    }

    const saveButtonHander = (e) => {
        if (selectPlatform === "") {
            alert("플렛폼을 선택하쇼");
            return;
        }
        if (platform === "new") {
            firestore
            .collection(selectPlatform)
            .doc(currentAccont)
            .set({...accountInfo, 
                startDate:moment(accountInfo.startDate).format("YYYY-MM-DD"),
                endDate:moment(accountInfo.endDate).format("YYYY-MM-DD")
            }, { merge: true }).then(() => {
                navigate("/admin/accounts");
                alert("추가 되었습니다.")
            });
        } else {
            firestore
            .collection(selectPlatform)
            .doc(currentAccont)
            .set({...accountInfo, 
                startDate:moment(accountInfo.startDate).format("YYYY-MM-DD"),
                endDate:moment(accountInfo.endDate).format("YYYY-MM-DD")
            }, { merge: true }).then(() => {
                navigate("/admin/accounts");
                alert("수정 되었습니다.")
            });
        }
        
    }
    return (
        <>
            <div className="container">
                <div className="page__top">
                    <h3 className="page__top--title">계정관리</h3>
                    <div className="page__top--right">
                        <a onClick={saveButtonHander} className="page__top--add">계정정보 {platform === "new" ? "추가" : "수정"}</a>
                        <button onClick={setDisable} className="page__top--add">{accountInfo.disable?"활성화":"비활성화"}</button>
                    </div>
                </div>

                <div className="user_info">
                    <form action="">
                        <table className="table">
                            <colgroup>
                                <col style={{ "width": "15%" }} />
                                <col style={{ "width": "auto" }} />
                                <col style={{ "width": "15%" }} />
                                <col style={{ "width": "auto" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>
                                        상태
                                    </th>
                                    <td colSpan={3}>{accountInfo.disable?"비활성화 상태":"활성화 상태"}</td>
                                </tr>
                                <tr>
                                    <th>OTT구분</th>
                                    <td className="tal">
                                        <select disabled={platform !== "new"} name="" id="" className="table_select" value={selectPlatform} onChange={e => setSelectPlatform(e.target.value)}>
                                            <option value="">선택해주세요.</option>
                                            <option value="netflix">넷플릭스</option>
                                            <option value="wavve">웨이브</option>
                                            <option value="tving">티빙</option>
                                            <option value="coupang">쿠팡플레이</option>
                                            <option value="watcha">왓차</option>
                                            <option value="disney">디즈니플러스</option>
                                        </select>
                                    </td>
                                    <th>결제정보</th>
                                    <td className="tal"><input type="text" name="" id="" size="40" value={accountInfo.payment && accountInfo.payment} onChange={(e) => setAccountInfo({ ...accountInfo, payment: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td className="tal">
                                        <input disabled={platform !== "new"} type="text" name="" id="" size="40" value={currentAccont} onChange={(e) => setCurrentAccount(e.target.value)} />
                                    </td>
                                    <th>비밀번호</th>
                                    <td className="tal"><input type="text" name="" id="" size="40" value={accountInfo.password && accountInfo.password} onChange={(e) => setAccountInfo({ ...accountInfo, password: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <th>시작일</th>
                                    <td className="tal">
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            locale={ko}
                                            selected={moment(accountInfo.startDate, 'YYYY-MM-DD').toDate()}
                                            onChange={date => {
                                                setAccountInfo({ ...accountInfo, startDate: moment(date).format("YYYY-MM-DD") })
                                            }}
                                        />
                                    </td>
                                    <th>종료일</th>
                                    <td className="tal">
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            locale={ko}
                                            selected={moment(accountInfo.endDate, 'YYYY-MM-DD').toDate()}
                                            onChange={date => {
                                                setAccountInfo({ ...accountInfo, endDate: moment(date).format("YYYY-MM-DD") })
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AccountModify;
