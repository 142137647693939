import logo from '../logo.svg';
import '../App.css';
import { isEmpty, isLoaded, useFirebase, useFirestore } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const Root = () => {

  const firebase = useFirebase();
  const navigate = useNavigate();
  const auth = useSelector(state => state.firebase.auth)
  const firestore = useFirestore();

  function loginWithGoogle() {
    firebase
      .login({
        provider: "google",
        type: "popup",
      })
      .then(() => {
        navigate("/admin/dashboard");
      });
  }

  useEffect(async () => {
    let userInfo = await firestore.collection("users").doc(auth.uid).get();
    userInfo = userInfo.data()

    if (isLoaded(auth) && !isEmpty(auth) && userInfo) {
      if (userInfo.admin) {
        navigate("/admin/dashboard");
      }
    }
  })
  // const signInWithGoogle = () => {
  //   firebase
  //     .login({
  //       provider: "google",
  //       type: "popup",
  //     })
  //     .then(() => {
  //       navigate.push("/todos");
  //     });
  // };
  return (
    <div className="login">
        <div className="login__title">
          <img src="../img/login_symbol.png" alt="" />
          <h3>OTTV 서비스</h3>
          <p>관리자 로그인</p>
        </div>
        <button onClick={loginWithGoogle} className="login__submit">로그인</button>
    </div>
  );
}

export default Root;
