import { useNavigate } from "react-router";
import moment from 'moment';
import 'moment/locale/ko';

const LicenseItem = ({ license, info }) => {
    let navigate = useNavigate();

    var now = moment();//now
    var end = moment(info.expireDate);
    var dday = now.diff(end, 'days');
    if (dday > -2) {
      var style = {
        color: "red"
      }
    }
    
    return (
            <tr onClick={() => navigate("/admin/licenses/"+license)}>
                <td onClick={(e) => e.preventDefault()}>{license}</td>
                <td>{info.madeBy}</td>
                <td className="table_title">{info.name}</td>
                <td>{info.owner}</td>
                <td>{info.phone}</td>
                <td>{info.kakaoId}</td>
                <td>{info.startDate}</td>
                <td style={style}>{info.expireDate}</td>
                <td>사용중</td>
            </tr>
    )
}

{/* <th>번호</th>
<th>이름(매장명)</th>
<th>연락처</th>
<th>카카오톡ID</th>
<th>계약대수</th>
<th>시작일</th>
<th>만료일</th>
<th>상태</th> */}
export default LicenseItem


{/* <div
style={{
  textDecoration: isTodoItemDone && "line-through",
  opacity: isTodoItemDone ? 0.5 : 1,
}}
>
<input type="checkbox" name="" id="" onChange={handleChange} checked={isTodoItemDone} />
{title}
</div> */}