import { useNavigate } from "react-router";

const UserItem = ({ idx, group, info, refId }) => {
  let navigate = useNavigate();

  return (
    <tr onClick={() => navigate(`/admin/systems/users/${refId}`)} >
        <td>{refId}</td>
        <td><img src={info.avatarUrl} /></td>
        <td>{group}</td>
      <td>{info.email}</td>
      <td>{info.displayName}</td>
      <td>{info.admin?"사용가능": ""}</td>
    </tr>
  )
}

export default UserItem