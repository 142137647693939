import { useNavigate } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";

const HistoryItem = ({ idx, info, refId, license }) => {
  let navigate = useNavigate();

  console.log(license);
  return (
      <tr>
          <td>{parseInt(refId) + 1}</td>
          <td>{moment(info.date.toDate()).format("YYYY-MM-DD HH:mm:ss")}</td>
          <td>{info.type}</td>
          <td>{info.memo}</td>
          <td>{license?.name}</td>
          <td>{license?.owner}</td>
          <td>{license?.phone}</td>
          <td> <Link to={`/admin/licenses/${info.target}`} style={{color:'blue'}}>{info.target}</Link></td>
          <td>{info.point.toLocaleString() || "0"}</td>
          <td>{info.madeBy}</td>
    </tr>
  )
}

export default HistoryItem