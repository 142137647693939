import logo from './logo.svg';
import './App.css';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
import Root from './components/root'
import { isLoaded, isEmpty, useFirebase, useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import GNBHeader from './components/common/header';
import AccountList from './components/accountList';
import AccountModify from './components/accountModify';
import LicenseList from './components/licenseList';
import LicenseModify from './components/licenseModify';
import GroupComponent from './components/systems/groupComponent';
import GroupList from './components/systems/groupList';
import GroupModify from './components/systems/groupModify';
import UserList from './components/systems/userList';
import UserModify from './components/systems/userModify';
import Dashboard from './components/dashboard';
import { useLocation } from 'react-router';
import HistoryList from './components/systems/historyList';
const ProtectedRoute = ({setCurrentGroup}) => {
  const auth = useSelector(state => state.firebase.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const firebase = useFirebase();
  const firestore = useFirestore();
  useFirestoreConnect(['groups'])

  const groups = useSelector((state) => state.firestore.data.groups);
  const [groupInfo, setGroupInfo] = useState({});
  
  useEffect(async () => {
    if (isLoaded(auth) && isEmpty(auth)) {
      navigate("/")
    } else if (isLoaded(auth) && !isEmpty(auth)) {
      const userInfo = await firestore.collection("users").doc(auth.uid).get();
      if (!userInfo.data().admin) {
        alert("관리자 권한이 없습니다.")
        firebase.logout();
      } else {
        
        if (isLoaded(groups)) {
          var isAdmin = false;
          Object.entries(groups).map((data, idx) => {
            var item = data[1];
            Object.values(item.users).map(async user => {
              if (userInfo.id === user.id) {
                isAdmin = item.admin;

                let collections = await firestore.collection('groups').doc(data[0]).collection("history").get();
    
                var history = [];
                collections.forEach(collection => {
                    history.push(collection.data());
                });
                history = history.reverse();
                setGroupInfo({...item, history:history});
                setCurrentGroup({...item, uid:data[0], history:history});
              }
            });
          });
          if (isAdmin) {
          } else if (
            !pathname.startsWith('/admin/licenses') &&
            !pathname.startsWith('/admin/accounts') &&
            !pathname.startsWith('/admin/dashboard')&&
            !pathname.startsWith('/admin/pointHistory')) {
            alert('해당 그룹에서 이용할 권한이 없습니다.');
            navigate("/admin/licenses");
          }
        }
      }
    }
  }, [groups]);
  return <>
    <GNBHeader groupInfo={groupInfo} />
    <div id="contents">
      <Outlet />
    </div>
  </>
};


// const ProtectedAdminRoute = ({ redirectPath = '/' }) => {
//   const auth = useSelector(state => state.firebase.auth);
//   const navigate = useNavigate();
//   const firebase = useFirebase();
//   const firestore = useFirestore();
//   useFirestoreConnect(['groups'])

//   const groups = useSelector((state) => state.firestore.data.groups);

//   useEffect(async () => {
//     if (isLoaded(auth) && isEmpty(auth)) {
//       navigate("/")
//     } else if (isLoaded(auth) && !isEmpty(auth)) {
//       const userInfo = await firestore.collection("users").doc(auth.uid).get();
//       if (!userInfo.data().admin) {
//         alert("관리자 권한이 없습니다.")
//       } else {
//         if (isLoaded(groups)) {
//           Object.values(groups).map((item) => {
//             var isAdmin = false;
//             Object.values(item.users).map(async user => {
//               if (userInfo.id === user.id) {
//                 console.log(item);
//                 isAdmin = item.admin;
//                 setIsAdminGroup(item.admin);
//               }
//             });
//           });
//           if (isAdmin) {
  
//           } else {
//             alert('권한이 없습니다.');
//             navigate("/admin/licenses");
//           }
//         }
//       }
//     }
//   });
//   return <>
//     <Outlet />
//     </>
// };



function App() {
  const [currentGroup, setCurrentGroup] = useState({});
  const auth = useSelector(state => state.firebase.auth);

  return (
    <Routes>
      <Route exact path="/" element={<Root />} />
      <Route path="/admin" element={<ProtectedRoute setCurrentGroup={setCurrentGroup} />}>

        <Route path="licenses" element={<LicenseList group={currentGroup}/>} />
        <Route path="dashboard" element={<Dashboard group={currentGroup}/>} />
        <Route index path="licenses/:licenseId" element={<LicenseModify group={currentGroup} auth={auth}/>} />
        {/* <Route path="" element={<ProtectedAdminRoute />} > */}
        <Route path="accounts" element={<AccountList group={currentGroup}/>} />
        <Route index path="accounts/:platform" element={<AccountModify group={currentGroup}/>} />
        <Route index path="accounts/:platform/:accountId" element={<AccountModify group={currentGroup}/>} />
        <Route index path="pointHistory" element={<HistoryList group={currentGroup}/>} />
        <Route index path="systems" element={<GroupComponent />} />
        <Route index path="systems/groups" element={<GroupList />} />
        <Route index path="systems/groups/:groupId" element={<GroupModify auth={auth}/>} />
        <Route index path="systems/users" element={<UserList />} />
        <Route index path="systems/users/:userId" element={<UserModify />} />
        {/* </Route> */}
      </Route>
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  );
}

export default App;
