import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import GroupItem from "./groupItem";
import { Link } from "react-router-dom";
import UserItem from "./userItem";

const UserList = () => {
  
  useFirestoreConnect(['users', 'groups'])

  const users = useSelector((state) => state.firestore.data.users);
  const groups = useSelector((state) => state.firestore.data.groups);

  return (
    <>
      <div className="container">
        <div className="page__top">
          <h3 className="page__top--title">유저관리</h3>
          <div className="page__top--right">
            {/* <Link to="new" className="page__top--add">유저등록</Link> */}
          </div>
        </div>

        <table className="table">
          <colgroup>
            <col style={{ "width": "180px" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "240px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>uid</th>
              <th>아바타</th>
              <th>그룹</th>
              <th>이메일</th>
              <th>이름</th>
              <th>접속 가능여부</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              Object.entries(users).map((data, idx) => {
                  let group = "";
                groups && Object.values(groups).map((item) => {
                    let groupTemp = item.name;
                    Object.values(item.users).map(async user => {
                        console.log(user.id);
                        if (data[0] === user.id) {
                            group = groupTemp;
                        }
                    })
                })
                 return <UserItem
                    key={idx}
                    idx={idx}
                    group={group}
                    refId={data[0]}
                    info={data[1]}
                  />
              }
              )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserList;
